<template>
        <div class="modal fade information-popup question" id="modal-campaign-delete" role="dialog">
                <div class="modal-dialog">
                        <!-- Modal content -->
                        <div class="modal-content">
                                <div class="modal-body">
                                        <img src="~/assets/images/client/modal-ic-delete.svg">
                                        <h1>Do you really want to delete?</h1>
                                        <button @click.prevent="deleteCampaign" class="white-btn" type="button">Yes</button>
                                        <button @click.prevent="cancelDeleteCampaign" class="white-btn"
                                                type="button">No</button>
                                </div>
                        </div>
                </div>
        </div>
        <div class="modal fade information-popup" id="modal-campaign-delete-success" role="dialog">
                <div class="modal-dialog">
                        <!-- Modal content -->
                        <div class="modal-content">
                                <div class="modal-body">
                                        <img src="~/assets/images/client/modal-ic-delete.svg">
                                        <h1>Successfully deleted</h1>
                                        <br>
                                        <!-- TODO(aes)
                                           - unless request.path == "/campaigns"
                                             %h6 You will be redirected.
                                        -->
                                </div>
                        </div>
                </div>
        </div>
</template>

<script setup>
const emit = defineEmits([
        "delete-campaign",
        "cancel-delete-campaign",
])

const deleteCampaign = () => emit("delete-campaign")
const cancelDeleteCampaign = () => emit("cancel-delete-campaign")
</script>
